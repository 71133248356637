import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { hostname, useEndeavour } from "../hooks/useEndeavour";
import Search from "antd/es/transfer/search";
import {
  ClearOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import DataTable from "../components/DataTable";
import { timestampToLocalTime } from "anka-endeavour";
import Entity from "../components/Entity";

function EntitiesPage() {
  const endeavour = useEndeavour();
  return (
    <DataTable
      uri={`${hostname}/ep/resources/entities`}
      filters={[
        <Form.Item label="Type">
          <Select
            options={[
              { label: "Individual", value: "individual" },
              { label: "Corporate", value: "corporate" },
            ]}
          />
        </Form.Item>,
        <Form.Item label="Name">
          <Input />
        </Form.Item>,
      ]}
      tableColumns={[
        {
          title: "Time",
          align: "center",
          render: (data) => {
            return timestampToLocalTime(data.created_at);
          },
        },
        {
          title: "Email",
          align: "center",
          render: (data) => {
            return data.user?.email;
          },
        },
        {
          title: "Mobile",
          align: "center",
          render: (data) => {
            return data.user?.mobile;
          },
        },
        {
          title: "Type",
          align: "center",
          render: (data) => {
            return data.type.toUpperCase();
          },
        },
        {
          title: "Name",
          align: "center",
          render: (data) => {
            return data.name;
          },
        },
        {
          title: "Actions",
          render: (data) => {
            return (
              <Space>
                <a
                  onClick={() => {
                    endeavour.openDrawer({
                      width: 1024,
                      children: <Entity entityID={data.id} />,
                    });
                  }}
                >
                  <SearchOutlined />
                </a>
              </Space>
            );
          },
        },
      ]}
    />
  );
}

export default EntitiesPage;
