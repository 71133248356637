import { useQuery } from "@tanstack/react-query";
import DataTable from "../components/DataTable";
import { Descriptions, Form, Select, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { timestampToLocalTime } from "anka-endeavour";
import { useEndeavour } from "../hooks/useEndeavour";

function AlertsPage() {
  const endeavour = useEndeavour();

  return (
    <DataTable
      //   uri={"http://localhost/ep/resources/aml/alerts"}
      uri={"http://localhost/ep/resources/aml/rules"}
      //   tableDataSource={[
      //     {
      //       id: 1,
      //       time: 1726711107,
      //       name: "AML",
      //       description: "",
      //       user: {
      //         email: "abc@example.com",
      //       },
      //       deposit: {},
      //     },
      //   ]}
      filters={[
        <Form.Item label="Type">
          <Select />
        </Form.Item>,
      ]}
      tableColumns={[
        {
          title: "Time",
          align: "center",
          render: (data) => {
            return timestampToLocalTime(data.created_at);
          },
        },
        { title: "Type", align: "center", dataIndex: "name" },
        { title: "Description", align: "center", dataIndex: "description" },
        {
          title: "User",
          align: "center",
          render: (data) => {
            return data.user?.email;
          },
        },
        { title: "Deposit", align: "center" },
        { title: "Payout", align: "center" },
        {
          render: (data) => {
            return (
              <Space>
                <a
                  onClick={() => {
                    endeavour.openDrawer({
                      width: 1024,
                      children: <>Hello</>,
                    });
                  }}
                >
                  <SearchOutlined />
                </a>
              </Space>
            );
          },
        },
      ]}
    />
  );
}

export default AlertsPage;
