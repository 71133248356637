import { useQuery } from "@tanstack/react-query";
import { debug } from "anka-endeavour";
import { Image, Skeleton } from "antd";

function S3Image({ uri, ...props }) {
  const query = useQuery({
    queryKey: [uri],
    queryFn: () =>
      fetch(uri)
        .then((res) => res.json())
        .then((res) => {
          return res.payload;
        }),
  });

  if (query.isFetching) {
    return <Skeleton active />;
  }

  return (
    <Image
      src={`data:${query.data["Content-Type"]};base64,${query.data.Body}`}
      {...props}
    />
  );
}

export default S3Image;
