import { useQuery } from "@tanstack/react-query";
import { Button, Col, Descriptions, Row, Skeleton, Space, theme } from "antd";
import Status from "./Status";
import { hostname, useEndeavour } from "../hooks/useEndeavour";
import { useEffect } from "react";
import { BugOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import Account from "./Account";
import { useNavigate } from "react-router-dom";
import Amount from "./Amount";
import { timestampToLocalTime } from "anka-endeavour";
import RequestLogs from "./RequestLogs";

function Deposit({ transactionID }) {
  const { token } = theme.useToken();
  const endeavour = useEndeavour();
  const navigate = useNavigate();
  const query = useQuery({
    queryKey: ["deposits", transactionID],
    queryFn: () => {
      return fetch(`${hostname}/ep/resources/deposits/${transactionID}`)
        .then((res) => res.json())
        .then((res) => {
          return res.payload;
        });
    },
  });

  if (query.isFetching) {
    return <Skeleton active />;
  }

  return (
    <Row gutter={[token.padding * 2, token.padding * 2]}>
      <Col span={24}>
        <Space>
          {query.data.users.map((user) => (
            <Button
              icon={<UserOutlined />}
              onClick={() => {
                window.open(`/users/${user.id}`, "_blank");
              }}
            >{`View User: ${user.email}`}</Button>
          ))}
          <Button
            icon={<BugOutlined />}
            onClick={() => {
              endeavour.openActionSheet({
                height: 768,
                title: `Debugger: ${timestampToLocalTime(
                  query.data.created_at - 300
                )} to ${timestampToLocalTime(query.data.created_at + 1800)}`,
                children: (
                  <RequestLogs
                    data={{
                      created_at: [
                        query.data.created_at - 300,
                        query.data.created_at + 1800,
                      ],
                    }}
                  />
                ),
              });
            }}
          >
            Debug
          </Button>
        </Space>
      </Col>
      {/* <Col span={24}>
        <Space>
          <Button icon={<SearchOutlined />}>View Entity</Button>
        </Space>
      </Col> */}
      <Col span={24}>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Reference">
            {query.data.reference}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Status value={query.data.status} />
          </Descriptions.Item>
          <Descriptions.Item label="Order Amount">
            <Amount
              value={query.data.order_amount}
              currency={query.data.currency}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Received Amount">
            <Amount
              value={query.data.received_amount}
              currency={query.data.currency}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Cost Amount">
            <Amount
              value={query.data.cost_amount}
              currency={query.data.currency}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Fee Amount">
            <Amount
              value={query.data.fee_amount}
              currency={query.data.currency}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Margin Amount">
            <Amount
              value={query.data.fee_margin}
              currency={query.data.currency}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Created at">
            {timestampToLocalTime(query.data.created_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Processed at">
            {timestampToLocalTime(query.data.process_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Completed at">
            {timestampToLocalTime(query.data.completed_at)}
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={24}>
        <Account accountID={query.data.account_id} />
      </Col>
    </Row>
  );
}
export default Deposit;
