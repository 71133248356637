import { LoginOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Form,
  Input,
  Layout,
  Row,
  theme,
} from "antd";
import { useNavigate } from "react-router-dom";

const CustomTheme = {
  token: {
    fontFamily: "Kanit",
  },
  algorithm: [theme.darkAlgorithm],
};

function LoginPage() {
  const navigate = useNavigate();

  const onFinish = (values) => {
    navigate("/");
  };

  return (
    <ConfigProvider theme={CustomTheme}>
      <Layout>
        <Layout.Content>
          <Row
            align={"middle"}
            justify={"center"}
            style={{
              minHeight: "100vh",
            }}
          >
            <Col span={8}>
              <Card>
                <Form layout="vertical" onFinish={onFinish}>
                  <Form.Item label="Email">
                    <Input autoFocus />
                  </Form.Item>
                  <Form.Item label="Password">
                    <Input.Password />
                  </Form.Item>
                  <Form.Item label="One Time Password">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <Button
                      type="primary"
                      icon={<LoginOutlined />}
                      block
                      htmlType="submit"
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </ConfigProvider>
  );
}

export default LoginPage;
