import {
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
  Tabs,
  Typography,
} from "antd";
import DataTable from "../components/DataTable";
import { timestampToLocalTime } from "anka-endeavour";
import Amount from "../components/Amount";
import { EditOutlined } from "@ant-design/icons";
import { hostname, useEndeavour } from "../hooks/useEndeavour";
import { useQuery } from "@tanstack/react-query";

function PricingPage() {
  const endeavour = useEndeavour();
  const query = useQuery({
    queryKey: "exchange-rate",
    queryFn: () =>
      fetch(`${hostname}/ep/resources/exchange-rate`)
        .then((res) => res.json())
        .then((res) => res.payload),
  });

  return (
    <Card
      title="Pricing"
      style={{
        minHeight: "100%",
      }}
    >
      <Tabs
        // tabPosition="left"
        items={[
          {
            key: "markup",
            label: "Markup",
            children: (
              <DataTable
                uri={`http://localhost/ep/resources/channels/markup`}
                filters={[
                  <Form.Item label="Channel">
                    <Select
                      options={[
                        { value: 2, label: "Nium" },
                        { value: 3, label: "Geoswift" },
                      ]}
                    />
                  </Form.Item>,
                  <Form.Item label="From">
                    <Select
                      options={[
                        { value: "HKD", label: "HK Dollar" },
                        { value: "USD", label: "US Dollar" },
                      ]}
                    />
                  </Form.Item>,
                  <Form.Item label="To">
                    <Select
                      options={[
                        { value: "HKD", label: "HK Dollar" },
                        { value: "USD", label: "US Dollar" },
                      ]}
                    />
                  </Form.Item>,
                ]}
                tableColumns={[
                  {
                    title: "Time",
                    align: "center",
                    render: (data) => {
                      return timestampToLocalTime(data.updated_at);
                    },
                  },
                  {
                    title: "Type",
                    align: "center",
                    render: (data) => {
                      return data.type.toUpperCase();
                    },
                  },
                  {
                    title: "Name",
                    align: "center",
                    render: (data) => {
                      return data.channel.name;
                    },
                  },
                  {
                    title: "From",
                    align: "center",
                    render: (data) => {
                      return data.from.toUpperCase();
                    },
                  },
                  {
                    title: "To",
                    align: "center",
                    render: (data) => {
                      return data.to.toUpperCase();
                    },
                  },
                  {
                    title: "XE Markup",
                    align: "right",
                    render: (data) => {
                      return (
                        <Amount
                          value={data.exchange_rate_markup}
                          currency="%"
                        />
                      );
                    },
                  },
                  {
                    title: "Buy Rate",
                    align: "right",
                    render: (data) => {
                      return <Amount value={data.buy_rate} currency="%" />;
                    },
                  },
                  {
                    title: "Sell Rate",
                    align: "right",
                    render: (data) => {
                      return <Amount value={data.sell_rate} currency="%" />;
                    },
                  },
                  {
                    title: "Fixed Buy Fee",
                    align: "right",
                    render: (data) => {
                      return (
                        <Amount
                          value={data.fixed_buy_fee}
                          currency={data.fee_currency}
                        />
                      );
                    },
                  },
                  {
                    title: "Min Buy Fee",
                    align: "right",
                    render: (data) => {
                      return (
                        <Amount
                          value={data.min_buy_fee}
                          currency={data.fee_currency}
                        />
                      );
                    },
                  },
                  {
                    title: "Max Buy Fee",
                    align: "right",
                    render: (data) => {
                      return (
                        <Amount
                          value={data.max_buy_fee}
                          currency={data.fee_currency}
                        />
                      );
                    },
                  },
                  {
                    title: "Min Sell Fee",
                    align: "right",
                    render: (data) => {
                      return (
                        <Amount
                          value={data.min_sell_fee}
                          currency={data.fee_currency}
                        />
                      );
                    },
                  },
                  {
                    title: "Max Sell Fee",
                    align: "right",
                    render: (data) => {
                      return (
                        <Amount
                          value={data.max_sell_fee}
                          currency={data.fee_currency}
                        />
                      );
                    },
                  },
                  {
                    render: (data) => {
                      return (
                        <Space>
                          <a
                            onClick={() => {
                              endeavour.openModal({
                                title: `${data.channel.name} ${data.from}/${data.to}`,
                                width: 768,
                                children: (
                                  <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                      <Row
                                        gutter={[16, 16]}
                                        style={{
                                          padding: 24,
                                        }}
                                      >
                                        <Col span={24}>
                                          <Typography.Title
                                            level={4}
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            PnL Calculator
                                          </Typography.Title>
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          實收金額
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          <Amount
                                            value={1000000}
                                            currency={data.to}
                                          />
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          {data.channel.name} 成本
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          <Amount
                                            value={data.fixed_buy_fee}
                                            currency={data.fee_currency}
                                          />
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          {data.channel.name} Markup
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          <Amount
                                            value={0}
                                            currency={data.fee_currency}
                                          />
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          {data.from}/{data.to} XE
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          <Amount
                                            value={
                                              query.data[
                                                `${data.from}${data.to}`
                                              ]
                                            }
                                            currency=""
                                            precision={6}
                                          />
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          {data.from}/{data.to} XE Markup
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          <Amount
                                            value={
                                              query.data[
                                                `${data.from}${data.to}`
                                              ] * 1.01
                                            }
                                            currency=""
                                            precision={6}
                                          />
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          {data.from} 實扣金額
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          <Amount
                                            valueStyle={{
                                              fontWeight: "bold",
                                              fontSize:
                                                endeavour.token.fontSize,
                                            }}
                                            value={
                                              1000000 /
                                              (query.data[
                                                `${data.from}${data.to}`
                                              ] *
                                                1.01)
                                            }
                                            currency={data.from}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col span={12}>
                                      <Form
                                        layout="vertical"
                                        initialValues={data}
                                      >
                                        <Form.Item
                                          label="Buy Rate"
                                          name="buy_rate"
                                        >
                                          <Input />
                                        </Form.Item>
                                        <Form.Item
                                          label="Sell Rate"
                                          name="sell_rate"
                                        >
                                          <Input />
                                        </Form.Item>
                                        <Form.Item
                                          label="XE Markup"
                                          name="exchange_rate_markup"
                                        >
                                          <Input />
                                        </Form.Item>
                                        <Form.Item
                                          label="Fixed Buy Fee"
                                          name="fixed_buy_fee"
                                        >
                                          <Input />
                                        </Form.Item>
                                        <Form.Item label="Min Buy Fee">
                                          <Input />
                                        </Form.Item>
                                        <Form.Item label="Max Buy Fee">
                                          <Input />
                                        </Form.Item>
                                        <Form.Item label="Min Sell Fee">
                                          <Input />
                                        </Form.Item>
                                        <Form.Item label="Max Sell Fee">
                                          <Input />
                                        </Form.Item>
                                      </Form>
                                    </Col>
                                  </Row>
                                ),
                              });
                            }}
                          >
                            <EditOutlined />
                          </a>
                        </Space>
                      );
                    },
                  },
                ]}
              />
            ),
          },
          {
            key: "rates",
            label: "Rates",
            children: (
              <DataTable
                filters={[
                  <Form.Item label="Pairs">
                    <Input />
                  </Form.Item>,
                ]}
                tableColumns={[
                  { title: "Time", align: "center" },
                  { title: "Pairs", align: "center" },
                  { title: "Multiple", align: "center" },
                  { title: "Rate", align: "right" },
                  { title: "Actions" },
                ]}
              />
            ),
          },
        ]}
      />
    </Card>
  );
}

export default PricingPage;
