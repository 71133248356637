import { Form, Input, Space, Tag } from "antd";
import DataTable from "../components/DataTable";
import { hostname, useEndeavour } from "../hooks/useEndeavour";
import { timestampToLocalTime } from "anka-endeavour";
import Status from "../components/Status";
import {
  CheckCircleOutlined,
  ExpandOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import User from "../components/User";
import { useNavigate } from "react-router-dom";

function UsersPage() {
  const endeavour = useEndeavour();
  const navigate = useNavigate();
  return (
    <DataTable
      tableSize="small"
      uri={`${hostname}/ep/resources/users`}
      filters={[
        <Form.Item label="Email" name="email">
          <Input autoFocus />
        </Form.Item>,
        <Form.Item label="Mobile" name="mobile">
          <Input />
        </Form.Item>,
      ]}
      tableColumns={[
        {
          title: "Time",
          align: "center",
          render: (data) => {
            return timestampToLocalTime(data.created_at);
          },
        },
        {
          title: "Email",
          align: "center",
          render: (data) => {
            return (
              <Space>
                {data.email}
                {data.verified_at && (
                  <CheckCircleOutlined
                    style={{
                      color: "blue",
                    }}
                  />
                )}
              </Space>
            );
          },
        },
        { title: "Mobile", align: "center", dataIndex: "mobile" },
        {
          title: "Status",
          align: "center",
          render: (data) => {
            return <Status value={data.status} />;
          },
        },
        {
          title: "Actions",
          render: (data) => {
            return (
              <Space>
                <a
                  onClick={() => {
                    endeavour.openDrawer({
                      title: data.email,
                      width: 1024,
                      children: <User userID={data.id} />,
                    });
                  }}
                >
                  <SearchOutlined />
                </a>
                <a
                  onClick={() => {
                    navigate(`/users/${data.id}`);
                  }}
                >
                  <ExpandOutlined />
                </a>
              </Space>
            );
          },
        },
      ]}
    />
  );
}

export default UsersPage;
