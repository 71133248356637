import { useQuery } from "@tanstack/react-query";
import { debug, timestamp, timestampToLocalTime } from "anka-endeavour";
import {
  App,
  Button,
  Card,
  Col,
  Descriptions,
  Image,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Table,
} from "antd";
import Status from "./Status";
import { hostname, useEndeavour } from "../hooks/useEndeavour";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  LinkOutlined,
  PictureOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Account from "./Account";
import DataTable from "./DataTable";
import InlineTooltip from "./InlineTooltip";
import S3Image from "./S3Image";

function Entity({ entityID }) {
  const endeavour = useEndeavour();
  const app = App.useApp();
  const query = useQuery({
    queryKey: ["entities", entityID],
    queryFn: () => {
      return fetch(`${hostname}/ep/resources/entities/${entityID}`)
        .then((res) => res.json())
        .then((res) => {
          return res.payload;
        });
    },
  });

  if (query.isFetching) {
    return <Skeleton active />;
  }

  return (
    <Row gutter={[endeavour.token.padding * 2, endeavour.token.padding * 2]}>
      <Col span={24}>
        <Descriptions bordered column={1} size="small">
          <Descriptions.Item label="Type">
            {query.data.type.toUpperCase()}
          </Descriptions.Item>
          <Descriptions.Item label="Name">{query.data.name}</Descriptions.Item>
          <Descriptions.Item label="Address">
            {query.data.address}
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={24}>
        <Card title="Zoloz" size="small">
          <Table
            size="small"
            bordered
            pagination={false}
            dataSource={query.data.kyc}
            columns={[
              {
                title: "Time",
                align: "center",
                render: (data) => {
                  return timestampToLocalTime(data.created_at);
                },
              },
              {
                title: "Type",
                align: "center",
                render: (data) => {
                  return data.doc_type?.toUpperCase();
                },
              },
              {
                title: "Nationality",
                align: "center",
                render: (data) => {
                  return data.doc_country;
                },
              },
              {
                title: "Name",
                align: "center",
                render: (data) => {
                  return data.full_name;
                },
              },
              {
                title: "Gender",
                align: "center",
                render: (data) => {
                  return data.gender;
                },
              },
              {
                title: "Face Score",
                align: "center",
                render: (data) => {
                  return data.face_score;
                },
              },
              {
                title: "Spoof Check",
                align: "center",
                render: (data) => {
                  return (
                    <Space>
                      <InlineTooltip tooltip="Tamper Check">
                        {data.tamper_check ? (
                          <CheckOutlined
                            style={{
                              color: "blue",
                            }}
                          />
                        ) : (
                          <CloseOutlined
                            style={{
                              color: "red",
                            }}
                          />
                        )}
                      </InlineTooltip>
                      <InlineTooltip tooltip="Material Check">
                        {data.material_check ? (
                          <CheckOutlined
                            style={{
                              color: "blue",
                            }}
                          />
                        ) : (
                          <CloseOutlined
                            style={{
                              color: "red",
                            }}
                          />
                        )}
                      </InlineTooltip>
                      <InlineTooltip tooltip="Information Check">
                        {data.information_check ? (
                          <CheckOutlined
                            style={{
                              color: "blue",
                            }}
                          />
                        ) : (
                          <CloseOutlined
                            style={{
                              color: "red",
                            }}
                          />
                        )}
                      </InlineTooltip>
                      <InlineTooltip tooltip="Screen Recapture Check">
                        {data.screen_recapture_check ? (
                          <CheckOutlined
                            style={{
                              color: "blue",
                            }}
                          />
                        ) : (
                          <CloseOutlined
                            style={{
                              color: "red",
                            }}
                          />
                        )}
                      </InlineTooltip>
                      <InlineTooltip tooltip="Security Feature Check">
                        {data.security_feature_check ? (
                          <CheckOutlined
                            style={{
                              color: "blue",
                            }}
                          />
                        ) : (
                          <CloseOutlined
                            style={{
                              color: "red",
                            }}
                          />
                        )}
                      </InlineTooltip>
                    </Space>
                  );
                },
              },
              {
                title: "Status",
                align: "center",
                render: (data) => {
                  return <Status value={data.status} />;
                },
              },
              {
                title: "Actions",
                render: (data) => {
                  return (
                    <Space>
                      {
                        <a
                          onClick={() => {
                            endeavour.openModal({
                              title: `${data.full_name}`,
                              centered: true,
                              children: (
                                <Row gutter={[8, 8]}>
                                  <Col span={24}>
                                    <Descriptions bordered column={2}>
                                      <Descriptions.Item label="Nationality">
                                        {data.doc_country}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Document#">
                                        {data.doc_number}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Date of Birth">
                                        {data.birth}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Gender">
                                        {data.gender}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Face Score">
                                        {data.face_score}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="KYC Elapsed">
                                        {data.completed_at - data.started_at}{" "}
                                        seconds
                                      </Descriptions.Item>
                                    </Descriptions>
                                  </Col>
                                  <Col span={12}>
                                    <Card title="Document Front Side">
                                      <Image
                                        // preview={false}
                                        src={`data:image/jpeg;base64,${data.doc_front_image}`}
                                      />
                                    </Card>
                                  </Col>
                                  <Col span={12}>
                                    <Card title="Face Scan">
                                      <Image
                                        // preview={false}
                                        src={`data:image/jpeg;base64,${data.face_image}`}
                                      />
                                    </Card>
                                  </Col>
                                </Row>
                              ),
                              footer: [
                                <Button onClick={endeavour.closeModal}>
                                  Cancel
                                </Button>,
                                <Button icon={<CloseOutlined />}>
                                  Reject
                                </Button>,
                                <Button
                                  type="primary"
                                  icon={<CheckOutlined />}
                                  onClick={() => {
                                    // debug(
                                    //   `${hostname}/ep/resources/entities/${query.data.id}`,
                                    //   data.id
                                    // );
                                    fetch(
                                      `${hostname}/ep/resources/entities/${query.data.id}`,
                                      {
                                        method: "POST",
                                        body: JSON.stringify({
                                          ekyc_transaction_id: data.id,
                                        }),
                                      }
                                    )
                                      .then((res) => res.json())
                                      .then((res) => {})
                                      .finally(() => {
                                        query.refetch();
                                        endeavour.closeModal();
                                      });
                                  }}
                                >
                                  Approve
                                </Button>,
                              ],
                            });
                          }}
                        >
                          <EditOutlined />
                        </a>
                      }
                      {data.zoloz_reference && (
                        <a
                          onClick={() => {
                            window.open(
                              `https://hk-production-portal.zoloz.net/#/case/caseSearch/realiddetail?id=${data.zoloz_reference}`
                            );
                          }}
                        >
                          <LinkOutlined />
                        </a>
                      )}
                    </Space>
                  );
                },
              },
            ]}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card title="World Check One" size="small">
          <DataTable
            tableSize="small"
            tableDataSource={query.data.wc1}
            tableColumns={[
              {
                title: "Time",
                align: "center",
                render: (data) => {
                  return timestampToLocalTime(data.created_at);
                },
              },
              {
                title: "Reference",
                render: (data) => {
                  return data.reference;
                },
              },
              {
                title: "Provider Reference",
                render: (data) => {
                  return data.provider_reference;
                },
              },
              {
                title: "WC1 Reference",
                render: (data) => {
                  return (
                    <Space>
                      <Status value={data.status} />
                      {data.wc1_reference}
                    </Space>
                  );
                },
              },
              {
                render: (data) => {
                  return (
                    <Space>
                      {data.status !== "success" && (
                        <Popconfirm
                          title="Approve WC1?"
                          okText="Approve"
                          cancelText="Reject"
                          onConfirm={() => {
                            fetch(
                              `${hostname}/ep/resources/entities/${query.data.id}`,
                              {
                                method: "POST",
                                body: JSON.stringify({
                                  wc1_id: data.id,
                                }),
                              }
                            )
                              .then((res) => res.json())
                              .then((res) => {
                                if (res.code !== 200) {
                                  return;
                                }

                                app.notification.info({
                                  message: "Updated",
                                });
                              })
                              .finally(() => {
                                query.refetch();
                              });
                          }}
                          onCancel={() => {
                            query.refetch();
                          }}
                        >
                          <EditOutlined
                            style={{
                              color: "rgb(22, 119, 255)",
                            }}
                          />
                        </Popconfirm>
                      )}
                    </Space>
                  );
                },
              },
            ]}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card title="Supporting Documents" size="small">
          <DataTable
            tableSize="small"
            tableDataSource={query.data.files}
            tableColumns={[
              {
                title: "Time",
                align: "center",
                render: (data) => {
                  return timestampToLocalTime(data.created_at);
                },
              },
              {
                title: "Type",
                align: "center",
                render: (data) => {
                  return data.type.toUpperCase();
                },
              },
              {
                title: "Text",
              },
              {
                title: "Image",
                align: "center",
                render: (data) => {
                  return (
                    <a
                      onClick={() => {
                        endeavour.openModal({
                          title: data.type.toUpperCase(),
                          children: (
                            <S3Image
                              uri={`${hostname}/ep/resources/entity-files/${data.uuid}`}
                            />
                          ),
                        });
                      }}
                    >
                      <SearchOutlined />
                    </a>
                  );
                },
              },
              {
                render: (data) => {
                  return (
                    <Space>
                      {data.type.toUpperCase() ===
                        "RESIDENTIAL_ADDRESS_PROOF" && (
                        <Popconfirm
                          title="Approve Entity with this Proof ?"
                          okText="Approve"
                          cancelText="Cancel"
                          onConfirm={() => {
                            fetch(
                              `${hostname}/ep/resources/entities/${query.data.id}`,
                              {
                                method: "POST",
                                body: JSON.stringify({
                                  file_id: data.id,
                                }),
                              }
                            )
                              .then((res) => res.json())
                              .then((res) => {
                                if (res.code !== 200) {
                                  return;
                                }

                                app.notification.info({
                                  message: "Approved",
                                });
                              })
                              .finally(() => {
                                query.refetch();
                              });
                          }}
                          onCancel={() => {
                            query.refetch();
                          }}
                        >
                          <EditOutlined
                            style={{
                              color: "rgb(22, 119, 255)",
                            }}
                          />
                        </Popconfirm>
                      )}
                    </Space>
                  );
                },
              },
            ]}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card title="Cards" size="small">
          <Table
            size="small"
            bordered
            rowKey={"id"}
            columns={[
              {
                title: "Time",
                align: "center",
                render: (data) => {
                  return timestampToLocalTime(data.created_at);
                },
              },
              {
                title: "Status",
                align: "center",
                render: (data) => {
                  return <Status value={data.status} />;
                },
              },
              {
                title: "Issuer",
                align: "center",
                render: (data) => {
                  return data.issuer;
                },
              },
              {
                title: "Card",
                align: "center",
                render: (data) => {
                  if (data.card_first6 === null) {
                    return;
                  }
                  return `${data.card_first6.slice(
                    0,
                    4
                  )} ${data.card_first6.slice(4)}** **** ${data.card_last4}`;
                },
              },
              {
                title: "ECI",
                align: "center",
                render: (data) => {
                  return data.eci;
                },
              },
              {
                title: "Remark",
                align: "center",
                render: (data) => {
                  return data.remark;
                },
              },
              {
                title: "Actions",
                render: (data) => {
                  // console.log(data.status);
                  if (data.status !== "pending") {
                    return;
                  }
                  return (
                    <a
                      onClick={() => {
                        endeavour.openModal({
                          title: `Approve Card?`,
                          width: 600,
                          children: (
                            <Row
                              gutter={[
                                endeavour.token.padding * 2,
                                endeavour.token.padding * 2,
                              ]}
                            >
                              <Col span={24}>
                                <Descriptions size="small" bordered column={2}>
                                  <Descriptions.Item label="KYC Name" span={2}>
                                    {query.data.name}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Issuer">
                                    {data.issuer}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Card">
                                    {`${data.card_first6.slice(
                                      0,
                                      4
                                    )} ${data.card_first6.slice(4)}** **** ${
                                      data.card_last4
                                    }`}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Proof Uploaded at">
                                    {timestampToLocalTime(data.proof_at)}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Approved at">
                                    {timestampToLocalTime(data.approved_at)}
                                  </Descriptions.Item>
                                </Descriptions>
                              </Col>
                              <Col span={24}>
                                <DataTable
                                  tableSize="small"
                                  tableDataSource={data.files}
                                  tableColumns={[
                                    {
                                      title: "Time",
                                      align: "center",
                                      render: (data) => {
                                        return timestampToLocalTime(
                                          data.created_at
                                        );
                                      },
                                    },
                                    {
                                      title: "Image",
                                      align: "center",
                                      render: (data) => {
                                        return (
                                          <S3Image
                                            uri={`${hostname}/ep/resources/entity-card-files/${data.uuid}`}
                                          />
                                        );
                                      },
                                    },
                                  ]}
                                />
                              </Col>
                            </Row>
                          ),
                          footer: [
                            <Button
                              onClick={() => {
                                endeavour.closeModal();
                              }}
                            >
                              Cancel
                            </Button>,
                            <Button
                              type="primary"
                              onClick={() => {
                                debug(data);
                                fetch(
                                  `${hostname}/ep/resources/entities/${data.entity_id}/cards/${data.id}`,
                                  {
                                    method: "POST",
                                    body: JSON.stringify({
                                      type: "approve",
                                    }),
                                  }
                                )
                                  .then((res) => res.json())
                                  .then((res) => {})
                                  .finally(() => {
                                    query.refetch();
                                    endeavour.closeModal();
                                  });
                              }}
                            >
                              Approve
                            </Button>,
                          ],
                        });
                      }}
                    >
                      <EditOutlined />
                    </a>
                  );
                },
              },
            ]}
            pagination={false}
            dataSource={query.data.cards}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card title="Beneficiaries">
          <DataTable
            uri={`${hostname}/ep/resources/entities/${entityID}/beneficiaries`}
            tableSize="small"
            tableColumns={[
              {
                title: "Time",
                align: "center",
                render: (data) => {
                  return timestampToLocalTime(data.created_at);
                },
              },
              {
                title: "Name",
                align: "center",
                render: (data) => {
                  return <Space>{data.name}</Space>;
                },
              },
              {
                title: "Country",
                align: "center",
                render: (data) => {
                  return data.country;
                },
              },
              {
                title: "ID Type",
                align: "center",
                render: (data) => {
                  return data.id_type?.toUpperCase();
                },
              },
              {
                title: "Account Name",
                align: "center",
                render: (data) => {
                  return data.bank_account_name;
                },
              },
              {
                title: "Account Number",
                align: "center",
                render: (data) => {
                  return data.bank_account_number;
                },
              },
              {
                title: "SWIFT Code",
                align: "center",
                render: (data) => {
                  return data.bank_swift_code;
                },
              },
              {
                title: "Status",
                align: "center",
                render: (data) => {
                  return <Status value={data.status} />;
                },
              },
              {
                title: "WC1",
                render: (data) => {
                  if (!data.wc1) {
                    return;
                  }
                  return (
                    <Space>
                      <Status value={data.wc1.status} />
                      {data.wc1.wc1_reference}
                    </Space>
                  );
                },
              },
              {
                render: (data) => {
                  return (
                    <Space>
                      <Popconfirm
                        title="Approve Beneficiary ?"
                        okText="Approve"
                        cancelText="Cancel"
                        onConfirm={() => {
                          fetch(
                            `${hostname}/ep/resources/entities/${query.data.id}`,
                            {
                              method: "POST",
                              body: JSON.stringify({
                                file_id: data.id,
                              }),
                            }
                          )
                            .then((res) => res.json())
                            .then((res) => {
                              if (res.code !== 200) {
                                return;
                              }

                              app.notification.info({
                                message: "Approved",
                              });
                            })
                            .finally(() => {
                              query.refetch();
                            });
                        }}
                        onCancel={() => {
                          query.refetch();
                        }}
                      >
                        <EditOutlined
                          style={{
                            color: "rgb(22, 119, 255)",
                          }}
                        />
                      </Popconfirm>
                    </Space>
                  );
                },
              },
            ]}
          />
        </Card>
      </Col>
      {query.data.accounts.map((account) => {
        return (
          <Col span={24}>
            <Card
              size="small"
              title={`${account.currency} Account#${account.account_number}`}
            >
              <Account accountID={account.id} />
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}

export default Entity;
