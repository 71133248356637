import { DatePicker } from "antd";
import dayjs from "dayjs";

const presets = [
  {
    label: "Today",
    value: [dayjs(), dayjs()],
  },
  {
    label: "Yesterday",
    value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
  },
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },

  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },

  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },

  {
    label: "Last 90 Days",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
];

function DateRangePicker({ ...props }) {
  return <DatePicker.RangePicker presets={presets} {...props} />;
}

export default DateRangePicker;
