import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Container from "./components/Container";
import DashboardPage from "./pages/DashboardPage";
import UsersPage from "./pages/UsersPage";
import UserPage from "./pages/UserPage";
import EntitiesPage from "./pages/EntitiesPage";
import SettingsPage from "./pages/SettingsPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReportsPage from "./pages/ReportsPage";
import AccountsPage from "./pages/AccountsPage";
import TransactionsPage from "./pages/TransactionsPage";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import AlertsPage from "./pages/AlertsPage";
import PricingPage from "./pages/PricingPage";
import BeneficiariesPage from "./pages/BeneficiariesPage";

Spin.setDefaultIndicator(
  <LoadingOutlined
    style={{
      animationDuration: ".2s",
    }}
  />
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route element={<Container />}>
            <Route index element={<DashboardPage />} />
            <Route path="users" element={<UsersPage />} />
            <Route path="users/:userID" element={<UserPage />} />
            <Route path="entities" element={<EntitiesPage />} />
            <Route path="accounts" element={<AccountsPage />} />
            <Route path="beneficiaries" element={<BeneficiariesPage />} />
            <Route path="transactions" element={<TransactionsPage />} />
            <Route path="alerts" element={<AlertsPage />} />
            {/* <Route path="reports" element={<ReportsPage />} /> */}
            <Route path="pricing" element={<PricingPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
