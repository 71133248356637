import { useQuery } from "@tanstack/react-query";
import { useEndeavour, hostname } from "../hooks/useEndeavour";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Skeleton,
  Space,
  Tabs,
} from "antd";
import { curl } from "../";
import Entity from "./Entity";
import { timestampToLocalTime } from "anka-endeavour";
import { EditOutlined, KeyOutlined, UnlockOutlined } from "@ant-design/icons";

function User({ userID }) {
  const endeavour = useEndeavour();
  const query = useQuery({
    queryKey: ["users", userID],
    queryFn: () => {
      return fetch(`${hostname}/ep/resources/users/${userID}`)
        .then((res) => res.json())
        .then((res) => res.payload);
    },
  });

  if (query.isFetching) {
    return <Skeleton active />;
  }

  return (
    <Row gutter={[endeavour.token.padding * 2, endeavour.token.padding * 2]}>
      <Col span={24}>
        <Space>
          <Button icon={<EditOutlined />}>Manual Verify</Button>
          <Button icon={<UnlockOutlined />}>Unlock User</Button>
          <Button icon={<KeyOutlined />}>Reset Password</Button>
        </Space>
      </Col>
      <Col span={24}>
        <Descriptions bordered>
          <Descriptions.Item label="Email">
            {query.data.email}
          </Descriptions.Item>
          <Descriptions.Item label="Mobile">
            {query.data.mobile}
          </Descriptions.Item>
          <Descriptions.Item label="Verified at">
            {timestampToLocalTime(query.data.verified_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Unlock at">
            {timestampToLocalTime(query.data.unlocked_at)}
          </Descriptions.Item>
        </Descriptions>
      </Col>
      {query.data.entities.map((entity) => {
        return (
          <Col span={24}>
            <Card>
              <Entity entityID={entity.id} />
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}

export default User;
