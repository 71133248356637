import { Form, Input, Select, Space, Statistic } from "antd";
import DataTable from "../components/DataTable";
import { hostname, useEndeavour } from "../hooks/useEndeavour";
import { SearchOutlined } from "@ant-design/icons";
import Account from "../components/Account";

function AccountsPage() {
  const endeavour = useEndeavour();
  return (
    <DataTable
      uri={`${hostname}/ep/resources/accounts`}
      filters={[
        <Form.Item label="Account#">
          <Input />
        </Form.Item>,
        <Form.Item label="Currency">
          <Select allowClear>
            <Select.Option value="HKD">Hong Kong Dollar</Select.Option>
            <Select.Option value="CNY">Rénmínbì</Select.Option>
            <Select.Option value="USD">US Dollar</Select.Option>
          </Select>
        </Form.Item>,
      ]}
      tableColumns={[
        {
          title: "Account#",
          align: "center",
          render: (data) => {
            return data.account_number;
          },
        },
        {
          title: "Balance",
          align: "right",
          render: (data) => {
            return (
              <Statistic
                suffix={data.currency}
                value={data.net_balance}
                precision={2}
                valueStyle={{
                  fontSize: endeavour.token.fontSize,
                }}
              />
            );
          },
        },
        {
          title: "Actions",
          render: (data) => {
            return (
              <Space>
                <a
                  onClick={() => {
                    endeavour.openDrawer({
                      width: 1024,
                      children: <Account accountID={data.id} />,
                    });
                  }}
                >
                  <SearchOutlined />
                </a>
              </Space>
            );
          },
        },
      ]}
    />
  );
}

export default AccountsPage;
