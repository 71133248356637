import { useQuery } from "@tanstack/react-query";
import { Col, Descriptions, Row, Skeleton, Statistic } from "antd";
import DataTable from "./DataTable";
import { timestampToLocalTime } from "anka-endeavour";
import { hostname, useEndeavour } from "../hooks/useEndeavour";

function Account({ accountID }) {
  const endeavour = useEndeavour();
  const query = useQuery({
    queryKey: ["accounts", accountID],
    queryFn: () => {
      return fetch(`${hostname}/ep/resources/accounts/${accountID}`)
        .then((res) => res.json())
        .then((res) => {
          return res.payload;
        });
    },
  });

  if (query.isFetching) {
    return <Skeleton active />;
  }

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Descriptions bordered size="small">
          <Descriptions.Item label="Account#">
            {query.data.account_number}
          </Descriptions.Item>
          <Descriptions.Item label="Balance">
            <Statistic
              value={query.data.net_balance}
              suffix={query.data.currency}
              precision={2}
              valueStyle={{
                fontSize: endeavour.token.fontSize,
              }}
            />
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={24}>
        <DataTable
          tableSize="small"
          uri={`${hostname}/ep/resources/accounts/${accountID}/changes`}
          tableTitle={"Changes"}
          tableColumns={[
            {
              title: "Time",
              align: "center",
              render: (data) => {
                return timestampToLocalTime(data.created_at);
              },
            },
            {
              title: "Type",
              align: "center",
              render: (data) => {
                return data.type.toUpperCase();
              },
            },
            {
              title: "Credit",
              align: "right",
              render: (data) => {
                if (data.change_amount > 0) {
                  return (
                    <Statistic
                      value={data.change_amount}
                      suffix={query.data.currency}
                      precision={2}
                      valueStyle={{
                        fontSize: endeavour.token.fontSize,
                      }}
                    />
                  );
                }

                return;
              },
            },
            {
              title: "Debit",
              align: "right",
              render: (data) => {
                if (data.change_amount < 0) {
                  return (
                    <Statistic
                      value={data.change_amount}
                      suffix={query.data.currency}
                      precision={2}
                      valueStyle={{
                        fontSize: endeavour.token.fontSize,
                      }}
                    />
                  );
                }

                return;
              },
            },
            // {
            //   title: "Amount",
            //   align: "right",
            //   render: (data) => {
            //     return (
            //       <Statistic
            //         value={data.change_amount}
            //         suffix={query.data.currency}
            //         precision={2}
            //         valueStyle={{
            //           fontSize: endeavour.token.fontSize,
            //         }}
            //       />
            //     );
            //   },
            // },
            {
              title: "Balance",
              align: "right",
              render: (data) => {
                return (
                  <Statistic
                    value={data.net_balance}
                    suffix={query.data.currency}
                    precision={2}
                    valueStyle={{
                      fontSize: endeavour.token.fontSize,
                    }}
                  />
                );
              },
            },
          ]}
        />
      </Col>
    </Row>
  );
}

export default Account;
