import { Statistic } from "antd";
import { useEndeavour } from "../hooks/useEndeavour";

function Amount({ value, currency = "HKD", precision = 2, ...props }) {
  const endeavour = useEndeavour();
  if (value === null || value === undefined || value == 0) {
    // value = 0.0;
    return;
  }
  return (
    <Statistic
      value={value}
      suffix={currency}
      precision={precision}
      valueStyle={{
        fontSize: endeavour.token.fontSize,
      }}
      {...props}
    />
  );
}

export default Amount;
