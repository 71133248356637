import { BugOutlined, LinkOutlined, UserOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Input,
  Row,
  Skeleton,
  Space,
  Tag,
} from "antd";
import { useNavigate } from "react-router-dom";
import { hostname, useEndeavour } from "../hooks/useEndeavour";
import Amount from "./Amount";
import DataTable from "./DataTable";
import RequestLogs from "./RequestLogs";
import Status from "./Status";
import { timestampToLocalTime } from "anka-endeavour";

function Payout({ payoutID }) {
  const navigate = useNavigate();
  const endeavour = useEndeavour();
  const query = useQuery({
    queryKey: ["payouts", payoutID],
    queryFn: () => {
      return fetch(`${hostname}/ep/resources/payouts/${payoutID}`)
        .then((res) => res.json())
        .then((res) => res.payload);
    },
  });

  if (query.isFetching) {
    return <Skeleton active />;
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Space>
          {query.data.users.map((user) => (
            <Button
              icon={<UserOutlined />}
              onClick={() => {
                window.open(`/users/${user.id}`, "_blank");
              }}
            >{`View User: ${user.email}`}</Button>
          ))}
          <Button
            onClick={() => {
              endeavour.openActionSheet({
                height: 768,
                title: `Debugger: ${timestampToLocalTime(
                  query.data.created_at - 300
                )} to ${timestampToLocalTime(query.data.created_at + 1800)}`,
                children: (
                  <RequestLogs
                    data={{
                      created_at: [
                        query.data.created_at - 300,
                        query.data.created_at + 1800,
                      ],
                    }}
                  />
                ),
              });
            }}
          >
            Debug
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <Descriptions bordered column={1} size="small">
          <Descriptions.Item label="Reference">
            {query.data.reference}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Status value={query.data.status} />
          </Descriptions.Item>
          <Descriptions.Item label="Method">
            {query.data.method.toUpperCase()}
          </Descriptions.Item>
          <Descriptions.Item label="Order">
            <Amount
              value={query.data.order_amount}
              currency={query.data.order_currency}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Exchange Rate">
            <Amount
              value={query.data.exchange_rate}
              currency={""}
              precision={6}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Deduct">
            <Amount
              value={query.data.total_amount}
              currency={query.data.amount_currency}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Cost">
            <Amount
              value={query.data.cost_amount}
              currency={query.data.amount_currency}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Fee">
            <Amount
              value={query.data.fee_amount}
              currency={query.data.amount_currency}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Margin">
            <Amount
              value={query.data.fee_margin}
              currency={query.data.amount_currency}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Message">
            {query.data.message}
          </Descriptions.Item>
          <Descriptions.Item label="Error">
            <Space>
              {query.data.error}
              {query.data.error && (
                <Button
                  icon={<BugOutlined />}
                  onClick={() => {
                    endeavour.openActionSheet({
                      children: (
                        <DataTable uri={`${hostname}/ep/resources/debug`} />
                      ),
                    });
                  }}
                >
                  Debug
                </Button>
              )}
            </Space>
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={24}>
        <Card
          title="Sender"
          size="small"
          extra={
            <Space>
              <Button size="small" icon={<LinkOutlined />}>
                KYC
              </Button>
              <Button size="small" icon={<LinkOutlined />}>
                WC1
              </Button>
              <Tag color="green">Low Risk</Tag>
            </Space>
          }
        >
          <Descriptions bordered column={1} size="small">
            <Descriptions.Item label="Email">
              {query.data.users[0].email}
            </Descriptions.Item>
            <Descriptions.Item label="Mobile">
              {query.data.users[0].mobile}
            </Descriptions.Item>
            <Descriptions.Item label="Name">
              {query.data.kyc.full_name}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {query.data.entity.address}
            </Descriptions.Item>
            <Descriptions.Item label="Gender">
              {query.data.kyc.gender}
            </Descriptions.Item>
            <Descriptions.Item label="Date of Birth">
              {query.data.kyc.birth}
            </Descriptions.Item>
            <Descriptions.Item label="Document Type">
              {query.data.kyc.doc_type.toUpperCase()}
            </Descriptions.Item>
            <Descriptions.Item label="Document Country">
              {query.data.kyc.doc_country}
            </Descriptions.Item>
            <Descriptions.Item label="Document #">
              <Input.Password
                size="small"
                value={query.data.kyc.doc_number}
                readOnly
                variant="borderless"
              />
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
      <Col span={24}>
        <Card
          title="Recipient"
          size="small"
          extra={
            <Space>
              <Button size="small" icon={<LinkOutlined />}>
                WC1
              </Button>
              <Tag color="green">Low Risk</Tag>
            </Space>
          }
        >
          <Descriptions bordered column={1} size="small">
            <Descriptions.Item label="Document Type">
              {query.data.beneficiary.beneficiary_id_type.toUpperCase()}
            </Descriptions.Item>
            <Descriptions.Item label="Document Number">
              {query.data.beneficiary.beneficiary_id_number}
            </Descriptions.Item>
            <Descriptions.Item label="Name">
              {query.data.beneficiary.beneficiary_first_name.toUpperCase()}{" "}
              {query.data.beneficiary.beneficiary_last_name.toUpperCase()}
            </Descriptions.Item>
            <Descriptions.Item label="Bank SWIFT Code">
              {query.data.beneficiary.beneficiary_bank_swift_code.toUpperCase()}
            </Descriptions.Item>
            <Descriptions.Item label="Bank Account Name">
              {query.data.beneficiary.beneficiary_bank_account_name.toUpperCase()}
            </Descriptions.Item>
            <Descriptions.Item label="Bank Account Number">
              {query.data.beneficiary.beneficiary_bank_account_number}
            </Descriptions.Item>
            <Descriptions.Item label="Fund Purpose">
              {query.data.beneficiary.fund_purpose.toUpperCase()}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
    </Row>
  );
}

export default Payout;
