import { Space, Tag } from "antd";
import { hostname } from "../hooks/useEndeavour";
import DataTable from "./DataTable";
import { timestampToLocalTime, debug } from "anka-endeavour";

const dump = (data) => {
  try {
    let input = JSON.parse(data);
    return JSON.stringify(input, null, 2);
  } catch (e) {
    debug(e);
    return data;
  }
};

function RequestLogs({ data }) {
  return (
    <DataTable
      uri={`${hostname}/ep/resources/debug`}
      uriQuery={data}
      tableSize="small"
      tableColumns={[
        {
          title: "Time",
          align: "center",
          render: (data) => {
            return timestampToLocalTime(data.created_at);
          },
        },
        {
          title: "Type",
          align: "center",
          render: (data) => {
            return data.type?.toUpperCase();
          },
        },
        {
          title: "Gateway",
          align: "center",
          render: (data) => {
            return data.gateway?.toUpperCase();
          },
        },
        {
          title: "URL",
          render: (data) => {
            return (
              <Space>
                <Tag>{data.http_code}</Tag>
                {data.url}
              </Space>
            );
          },
        },
        {
          title: "Request",
          render: (data) => {
            return <pre className="code">{dump(data.request)}</pre>;
          },
        },
        {
          title: "Response",
          render: (data) => {
            return <pre className="code">{dump(data.response)}</pre>;
          },
        },
      ]}
    />
  );
}

export default RequestLogs;
