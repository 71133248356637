import { Tooltip } from "antd";

function InlineTooltip({ tooltip, children }) {
  if (tooltip === undefined) {
    return children;
  }

  if (typeof children === "string") {
    return (
      <Tooltip title={tooltip}>
        <span
          style={{
            borderBottomWidth: 1,
            borderBottomStyle: "dashed",
          }}
        >
          {children}
        </span>
      </Tooltip>
    );
  }
  return <Tooltip title={tooltip}>{children}</Tooltip>;
}

export default InlineTooltip;
