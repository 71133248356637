import { Form, Input, Select, Space, Statistic, Tabs } from "antd";
import DataTable from "../components/DataTable";
import {
  DownloadOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { timestampToLocalTime } from "anka-endeavour";
import Status from "../components/Status";
import Amount from "../components/Amount";
import { hostname, useEndeavour } from "../hooks/useEndeavour";
import Deposit from "../components/Deposit";
import Payout from "../components/Payout";
import InlineCopyable from "../components/InlineCopyable";
import DateRangePicker from "../components/DateRangePicker";
import dayjs from "dayjs";
import InlineTooltip from "../components/InlineTooltip";

function TransactionsPage() {
  const endeavour = useEndeavour();

  return (
    <Tabs
      items={[
        {
          key: "deposit",
          icon: <DownloadOutlined />,
          label: "Pay In",
          children: (
            <DataTable
              tableSize="small"
              uri={`${hostname}/ep/resources/deposits`}
              filtersInitialValues={{
                created_at: [dayjs(), dayjs()],
              }}
              filters={[
                <Form.Item label="Date Range" name="created_at">
                  <DateRangePicker />
                </Form.Item>,
                // <Form.Item label="Method">
                //   <Select
                //     options={[
                //       { value: 5, label: "Checkout.com" },
                //       { value: 6, label: "33 Finance" },
                //     ]}
                //   />
                // </Form.Item>,
                <Form.Item label="Currency">
                  <Select
                    options={[
                      { value: "HKD", label: "HK Dollar" },
                      { value: "USD", label: "US Dollar" },
                    ]}
                  />
                </Form.Item>,
                <Form.Item label="Reference">
                  <Input />
                </Form.Item>,
              ]}
              tableColumns={[
                {
                  title: "Time",
                  align: "center",
                  render: (data) => {
                    return timestampToLocalTime(data.created_at);
                  },
                },
                {
                  title: "Method",
                  align: "center",
                  render: (data) => {
                    return data.method.name;
                  },
                },
                {
                  title: "User",
                  align: "center",
                  render: (data) => {
                    return data.user.email;
                  },
                },
                {
                  title: "Reference",
                  render: (data) => {
                    return (
                      <Space>
                        <Status value={data.status} />
                        {data.reference}
                      </Space>
                    );
                  },
                },
                {
                  title: "Payout",
                  render: (data) => {
                    return data.payout_transaction_id ? (
                      <Space>
                        <Status value={data.payout.status} />
                        {data.payout.reference}
                      </Space>
                    ) : (
                      ""
                    );
                  },
                },
                {
                  title: "Amount",
                  align: "right",
                  render: (data) => {
                    return (
                      <Statistic
                        value={data.amount}
                        suffix={data.currency}
                        precision={2}
                        valueStyle={{
                          fontSize: endeavour.token.fontSize,
                        }}
                      />
                    );
                  },
                },
                {
                  title: "Cost",
                  align: "right",
                  render: (data) => {
                    return (
                      <Amount
                        value={data.cost_amount}
                        currency={data.currency}
                      />
                    );
                  },
                },
                {
                  title: "Fee",
                  align: "right",
                  render: (data) => {
                    return (
                      <Amount
                        value={data.fee_amount}
                        currency={data.currency}
                      />
                    );
                  },
                },
                {
                  title: "Margin",
                  align: "right",
                  render: (data) => {
                    return (
                      <Amount
                        value={data.fee_margin}
                        currency={data.currency}
                      />
                    );
                  },
                },
                {
                  title: "Deduct",
                  align: "right",
                  render: (data) => {
                    return (
                      <Amount
                        value={data.total_amount}
                        currency={data.currency}
                      />
                    );
                  },
                },
                {
                  title: "Actions",
                  render: (data) => {
                    return (
                      <Space>
                        <a
                          onClick={() => {
                            endeavour.openDrawer({
                              width: 1024,
                              children: <Deposit transactionID={data.id} />,
                            });
                          }}
                        >
                          <SearchOutlined />
                        </a>
                      </Space>
                    );
                  },
                },
              ]}
            />
          ),
        },
        {
          key: "payout",
          icon: <UploadOutlined />,
          label: "Payout",
          children: (
            <DataTable
              tableSize="small"
              uri={`${hostname}/ep/resources/payouts`}
              filtersInitialValues={{
                created_at: [dayjs(), dayjs()],
              }}
              filters={[
                <Form.Item label="Date Range" name="created_at">
                  <DateRangePicker />
                </Form.Item>,
                // <Form.Item label="Method">
                //   <Select
                //     options={[
                //       { value: 5, label: "Checkout.com" },
                //       { value: 6, label: "33 Finance" },
                //     ]}
                //   />
                // </Form.Item>,
                <Form.Item label="Currency" name="currency">
                  <Select
                    options={[
                      { value: "HKD", label: "HK Dollar" },
                      { value: "USD", label: "US Dollar" },
                    ]}
                  />
                </Form.Item>,
                <Form.Item label="Reference" name="reference">
                  <Input autoFocus />
                </Form.Item>,
              ]}
              tableColumns={[
                {
                  title: "Time",
                  align: "center",
                  render: (data) => {
                    return timestampToLocalTime(data.created_at);
                  },
                },
                {
                  title: "Method",
                  align: "center",
                  render: (data) => {
                    return data.method.name;
                  },
                },
                {
                  title: "User",
                  align: "center",
                  render: (data) => {
                    return data.user.email;
                  },
                },
                {
                  title: "Payment",
                  align: "center",
                  render: (data) => {
                    return data.payout_request_id ? data.payment.reference : "";
                  },
                },
                {
                  title: "Reference",
                  render: (data) => {
                    return (
                      <Space>
                        <Status value={data.status} />
                        {data.status === "rejected" ? (
                          <InlineTooltip tooltip={data.error}>
                            {data.reference}
                          </InlineTooltip>
                        ) : (
                          data.reference
                        )}
                      </Space>
                    );
                  },
                },
                {
                  title: "Order",
                  align: "right",
                  render: (data) => {
                    return (
                      <Amount
                        value={data.order_amount}
                        currency={data.order_currency}
                      />
                    );
                  },
                },
                {
                  title: "Amount",
                  align: "right",
                  render: (data) => {
                    return (
                      <Amount value={data.amount} currency={data.currency} />
                    );
                  },
                },
                {
                  title: "Cost",
                  align: "right",
                  render: (data) => {
                    return (
                      <Amount
                        value={data.cost_amount}
                        currency={data.currency}
                      />
                    );
                  },
                },
                {
                  title: "Fee",
                  align: "right",
                  render: (data) => {
                    return (
                      <Amount
                        value={data.fee_amount}
                        currency={data.currency}
                      />
                    );
                  },
                },
                {
                  title: "Margin",
                  align: "right",
                  render: (data) => {
                    return (
                      <Amount
                        value={data.fee_margin}
                        currency={data.currency}
                      />
                    );
                  },
                },
                {
                  title: "Deduct",
                  align: "right",
                  render: (data) => {
                    return (
                      <Amount
                        value={data.total_amount}
                        currency={data.currency}
                      />
                    );
                  },
                },
                {
                  title: "Actions",
                  render: (data) => {
                    return (
                      <Space>
                        <a
                          onClick={() => {
                            endeavour.openDrawer({
                              width: 768,
                              children: <Payout payoutID={data.id} />,
                            });
                          }}
                        >
                          <SearchOutlined />
                        </a>
                      </Space>
                    );
                  },
                },
              ]}
            />
          ),
        },
      ]}
    />
  );
}

export default TransactionsPage;
