import { useParams } from "react-router-dom";
import User from "../components/User";
import { Card } from "antd";

function UserPage() {
  const { userID } = useParams();
  return (
    <Card>
      <User userID={userID} />
    </Card>
  );
}

export default UserPage;
